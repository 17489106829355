var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buildLog-container" },
    [
      _c("form-panel", [
        _vm.logInfo.length > 0
          ? _c(
              "div",
              _vm._l(_vm.logInfo, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "logBox" },
                  [
                    _c("div", { staticClass: "top" }, [
                      _c("span", [_vm._v(_vm._s(item.stageName))]),
                      _c("span", [_vm._v(_vm._s(item.realEndTime))]),
                    ]),
                    _c("div", { staticClass: "mid" }, [
                      _vm._v(_vm._s(item.remark)),
                    ]),
                    _vm._l(item.orderInfo, function (item2, index2) {
                      return _c("div", { key: index2, staticClass: "bot" }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("工单编号:"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "num",
                            on: {
                              click: function ($event) {
                                return _vm.goPageOrder(item2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(item2.serialNumber) +
                                _vm._s(_vm._f("orderStatus")(item2.orderStatus))
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                )
              }),
              0
            )
          : _c("div", [_vm._v("暂无装修日志")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }