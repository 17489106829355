<template>
  <div class="applyMaterial-container">
    <form-panel
      ref="form"
      :form="form"
    >
      <el-form-item label="施工许可" prop="constructionPermit" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="form.constructionPermit"
          :limit="4"
          >
            <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
          </v-uploader>
      </el-form-item>
      <el-form-item label="申请表" prop="applyTable" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="form.applyTable"
          :limit="6"
          >
            <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
          </v-uploader>
      </el-form-item>
      <el-form-item label="管理协议书" prop="manageAgreement" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="form.manageAgreement"
          :limit="6"
          >
            <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
          </v-uploader>
      </el-form-item>
      <el-form-item label="承诺书" prop="commitment" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="form.commitment"
          :limit="4"
          >
            <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
          </v-uploader>
      </el-form-item>
      <el-form-item label="图纸" prop="photoPaper" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="form.photoPaper"
          :limit="20"
          >
            <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
          </v-uploader>
      </el-form-item>
      <div slot='footerSlot'>
        <v-button class="botBtn" text="保存" type="success" @click="submitApply"></v-button>
      </div>
    </form-panel>
  </div>
</template>
<script>
import { uploadDecorationAnnexURL, uploadUrl } from '../api'
import { vUploader } from 'components/control'
export default {
  components: {
    vUploader
  },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      uploadUrl,
      form: {
        applyTable: [],
        commitment: [],
        constructionPermit: [],
        manageAgreement: [],
        photoPaper: []
      }
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBuildApplyInfo()
  },
  methods: {
    setBuildApplyInfo () {
      let { applyTable, commitment, constructionPermit, manageAgreement, photoPaper } = this.responseData.data.dataInfo
      this.form = {
        applyTable: applyTable.map(url => { return { url } }),
        commitment: commitment.map(url => { return { url } }),
        constructionPermit: constructionPermit.map(url => { return { url } }),
        manageAgreement: manageAgreement.map(url => { return { url } }),
        photoPaper: photoPaper.map(url => { return { url } })
      }
    },
    validIdCard (rule, value, callback) {
      if (value.length < 1) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    },
    submitApply () {
      if (!this.publicParams.id) {
        this.$alert('请先添加基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
      let isContinue = this.$refs.form.validate()
      if (!isContinue) {
        return
      }
      let data = this.form
      let params = {
        id: this.publicParams.id,
        applyTable: data.applyTable.map(img => img.url),
        commitment: data.commitment.map(img => img.url),
        constructionPermit: data.constructionPermit.map(img => img.url),
        manageAgreement: data.manageAgreement.map(img => img.url),
        photoPaper: data.photoPaper.map(img => img.url)
      }
      this.$axios.put(uploadDecorationAnnexURL, params).then(res => {
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          if (this.$route.query.id) {
            this.$emit('contact', {
              method: 'setActiveLabel',
              data: '装修日志'
            })
          } else {
            this.$router.go(-1)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.botBtn {
  width: 168px;
}
</style>
