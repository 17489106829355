<template>
  <div class="decorationREcordForm-container">
    <tabs-panel
      :tabs="tabs"
      :tabRouter="false"
      :before="getDetail"
      :activeLabel.sync="activeLabel"
    ></tabs-panel>
  </div>
</template>
<script>
import { TabsPanel } from 'components/bussiness'
import BaseInfoForm from './templateForm/BaseInfoForm'
import BuilderForm from './templateForm/BuilderForm'
import BuildUnitForm from './templateForm/BuildUnitForm'
import ApplyMaterial from './templateForm/ApplyMaterial'
import BuildLog from './templateForm/BuildLog'
import { uploadDecorationBaseURL } from './api'
export default {
  components: {
    TabsPanel
  },
  data () {
    return {
      activeLabel: '基础信息',
      tabs: [],
      public: {
        id: ''
      },
      response: {
        data: null
      }
    }
  },
  created () {
    this.tabs = [
      {
        label: '基础信息',
        component: BaseInfoForm,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      },
      {
        label: '施工人员',
        component: BuilderForm,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      },
      {
        label: '施工单位',
        component: BuildUnitForm,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      },
      {
        label: '申请资料',
        component: ApplyMaterial,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }
    ]
    if (this.$route.query.id) {
      this.tabs.push({
        label: '装修日志',
        component: BuildLog,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      })
    }
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    getDetail (next) {
      if (this.$route.query.id) {
        this.public.id = this.$route.query.id
        let params = {
          params: {
            id: this.$route.query.id
          }
        }
        this.$axios.get(`${uploadDecorationBaseURL}/${this.$route.query.id}`, params).then((res) => {
          if (res.status == 100) { //eslint-disable-line
            let { data } = res
            this.response.data = data
            next()
          }
        })
      } else {
        next()
      }
    }
  }
}
</script>
