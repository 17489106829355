var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buildUnitForm-container" },
    [
      _c(
        "form-panel",
        { ref: "form", attrs: { form: _vm.form } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "施工方式",
                prop: "constructionMode",
                rules: [
                  {
                    required: true,
                    message: "请选择施工方式",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.constructionModeOps },
                model: {
                  value: _vm.form.constructionMode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "constructionMode", $$v)
                  },
                  expression: "form.constructionMode",
                },
              }),
            ],
            1
          ),
          _vm.form.constructionMode === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "单位名称",
                        prop: "constructionCompanyName",
                        rules: [
                          {
                            required: true,
                            message: "请填写单位名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.constructionCompanyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "constructionCompanyName", $$v)
                          },
                          expression: "form.constructionCompanyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "营业执照",
                        prop: "licenceImg",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validIdCard,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-uploader",
                        {
                          ref: "myUpload",
                          attrs: {
                            action: _vm.uploadUrl,
                            fileList: _vm.form.licenceImg,
                            limit: 4,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              return _vm.$set(_vm.form, "licenceImg", $event)
                            },
                            "update:file-list": function ($event) {
                              return _vm.$set(_vm.form, "licenceImg", $event)
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                            _vm._v(
                              "注：图片要求：宽640px高640px，图片小于512KB"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "资质证书",
                        prop: "cerImg",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validIdCard,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-uploader",
                        {
                          ref: "myUpload",
                          attrs: {
                            action: _vm.uploadUrl,
                            fileList: _vm.form.cerImg,
                            limit: 4,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              return _vm.$set(_vm.form, "cerImg", $event)
                            },
                            "update:file-list": function ($event) {
                              return _vm.$set(_vm.form, "cerImg", $event)
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                            _vm._v(
                              "注：图片要求：宽640px高640px，图片小于512KB"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "负责人",
                prop: "headerImg",
                rules: [
                  {
                    required: true,
                    message: "请上传负责人头像",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: { action: _vm.uploadUrl, imgUrls: _vm.form.headerImg },
                  on: {
                    "update:imgUrls": function ($event) {
                      return _vm.$set(_vm.form, "headerImg", $event)
                    },
                    "update:img-urls": function ($event) {
                      return _vm.$set(_vm.form, "headerImg", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：建议宽180px高120px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "姓名",
                prop: "name",
                rules: [
                  { required: true, message: "请填写姓名", trigger: "blur" },
                ],
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "联系电话",
                prop: "mobile",
                rules: [
                  {
                    required: true,
                    message: "请填写联系电话",
                    trigger: "blur",
                  },
                  { validator: _vm.validatePhone, trigger: "blur" },
                ],
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile", $$v)
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "证件类型",
                prop: "cardType",
                rules: [
                  {
                    required: true,
                    message: "请选择证件类型",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.cardTypeOps },
                model: {
                  value: _vm.form.cardType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cardType", $$v)
                  },
                  expression: "form.cardType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "证件号码",
                prop: "cardNum",
                rules: [
                  {
                    required: true,
                    validator: _vm.validateCardNum,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.cardNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cardNum", $$v)
                  },
                  expression: "form.cardNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "证件照片",
                prop: "idCardImg",
                rules: [
                  {
                    required: true,
                    validator: _vm.validIdCard,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.form.idCardImg,
                    limit: 2,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      return _vm.$set(_vm.form, "idCardImg", $event)
                    },
                    "update:file-list": function ($event) {
                      return _vm.$set(_vm.form, "idCardImg", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：图片要求：宽180px高120px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
            [
              _c("v-button", {
                staticClass: "botBtn",
                attrs: { text: "保存", type: "success" },
                on: { click: _vm.submitBuildUnit },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }