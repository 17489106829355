var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
              submitMethod: _vm.submitMethod,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "基础信息" } },
            [
              _vm.id
                ? _c(
                    "div",
                    [
                      _c("el-form-item", { attrs: { label: "所属项目" } }, [
                        _vm._v(_vm._s(_vm.baseInfo.communityName)),
                      ]),
                      _c("el-form-item", { attrs: { label: "房号信息" } }, [
                        _vm._v(_vm._s(_vm.baseInfo.address)),
                      ]),
                      _c("el-form-item", { attrs: { label: "申请业主" } }, [
                        _vm._v(_vm._s(_vm.baseInfo.userName)),
                      ]),
                      _c("el-form-item", { attrs: { label: "联系电话" } }, [
                        _vm._v(_vm._s(_vm.baseInfo.userMobile)),
                      ]),
                      _c("el-form-item", { attrs: { label: "资料状态" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.baseInfo.dataStatus === 1 ? "已完善" : "待完善"
                          )
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "装修开始时间" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.baseInfo.realBeginDate
                              ? _vm.baseInfo.realBeginDate
                              : "-"
                          )
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "装修结束时间" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.baseInfo.realEndDate
                              ? _vm.baseInfo.realEndDate
                              : "-"
                          )
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "装修阶段" } }, [
                        _vm._v(_vm._s(_vm.baseInfo.decorationStageName)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.id
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业主房产信息",
                        prop: "roomId",
                        rules: [
                          {
                            required: true,
                            message: "请选择业主房产信息",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "查询房主", width: 380 },
                            on: { onChange: _vm.houseChange },
                            model: {
                              value: _vm.form.roomId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "roomId", $$v)
                              },
                              expression: "form.roomId",
                            },
                          },
                          "v-select2",
                          _vm.roomParams,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "预计开始",
                    prop: "beginDate",
                    rules: [
                      {
                        required: true,
                        message: "请填写预计开始",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-datepicker", {
                    attrs: { width: 180, type: "date" },
                    model: {
                      value: _vm.form.beginDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "beginDate", $$v)
                      },
                      expression: "form.beginDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "预计结束",
                    prop: "endDate",
                    rules: [
                      {
                        required: true,
                        message: "请填写预计结束",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-datepicker", {
                    attrs: { width: 180, type: "date" },
                    model: {
                      value: _vm.form.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endDate", $$v)
                      },
                      expression: "form.endDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "装修管理费" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否缴纳装修管理费",
                    prop: "isEarnestMoney",
                    rules: [
                      { required: true, message: "请选择", trigger: "change" },
                    ],
                  },
                },
                [
                  _c("label", { attrs: { for: "isEarnest-0" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.isEarnestMoney,
                          expression: "form.isEarnestMoney",
                        },
                      ],
                      attrs: { type: "radio", id: "isEarnest-0" },
                      domProps: {
                        value: 0,
                        checked: _vm._q(_vm.form.isEarnestMoney, 0),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.form, "isEarnestMoney", 0)
                        },
                      },
                    }),
                    _vm._v("\n          是\n        "),
                  ]),
                  _c(
                    "label",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { for: "isEarnest-1" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.isEarnestMoney,
                            expression: "form.isEarnestMoney",
                          },
                        ],
                        attrs: { type: "radio", id: "isEarnest-1" },
                        domProps: {
                          value: 1,
                          checked: _vm._q(_vm.form.isEarnestMoney, 1),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, "isEarnestMoney", 1)
                          },
                        },
                      }),
                      _vm._v("\n          否\n        "),
                    ]
                  ),
                ]
              ),
              _vm.form.isEarnestMoney === 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "缴纳金额",
                        prop: "earnestMoney",
                        rules: [
                          {
                            required: true,
                            message: "缴纳金额",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form.earnestMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "earnestMoney", $$v)
                          },
                          expression: "form.earnestMoney",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "垃圾清运方式",
                    prop: "trashClearMode",
                    rules: [
                      {
                        required: true,
                        message: "请选择垃圾清运方式",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.clearModeOps },
                    model: {
                      value: _vm.form.trashClearMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "trashClearMode", $$v)
                      },
                      expression: "form.trashClearMode",
                    },
                  }),
                ],
                1
              ),
              _vm.form.trashClearMode === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "清运费用",
                        prop: "trashClearFee",
                        rules: [
                          {
                            required: true,
                            message: "请填写清运费用",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { type: "number" },
                        on: { change: _vm.valTrashClearFee },
                        model: {
                          value: _vm.form.trashClearFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "trashClearFee", $$v)
                          },
                          expression: "form.trashClearFee",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.baseInfo.examineV &&
          _vm.baseInfo.examineV.itemVList[0].status === 0 &&
          _vm.id
            ? _c(
                "col2-block",
                { attrs: { title: "现场查验" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否需要", prop: "needExamine" } },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled:
                            _vm.baseInfo.examineV.status === 1 ||
                            (_vm.form.examineInfo &&
                              _vm.form.examineInfo.status === 1) ||
                            _vm.baseInfo.checkStatus === 0 ||
                            _vm.baseInfo.checkStatus === 1,
                          width: 180,
                          options: _vm.isNeedExamine,
                        },
                        on: { change: _vm.selectChange },
                        model: {
                          value: _vm.form.needExamine,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "needExamine", $$v)
                          },
                          expression: "form.needExamine",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.needExamine === 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "查验人",
                                prop: _vm.baseInfo.examineV.examineUserName,
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      disabled: _vm.isDisabled,
                                      width: 180,
                                      subjoin: _vm.roleExtraParams,
                                    },
                                    on: { onChange: _vm.examineChange },
                                    model: {
                                      value:
                                        _vm.baseInfo.examineV.examineUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.baseInfo.examineV,
                                          "examineUserId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "baseInfo.examineV.examineUserId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.examineParams,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划查验时间" } },
                            [
                              _c("v-datepicker", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  width: 180,
                                  type: "date",
                                },
                                model: {
                                  value: _vm.form.examineInfo.planTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.examineInfo,
                                      "planTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.examineInfo.planTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "查验结果" } },
                            [
                              _vm._v(
                                _vm._s(
                                  this.form.examineInfo.status === 1
                                    ? "通过"
                                    : _vm.spectionResults
                                ) + "\n          "
                              ),
                              _vm.form.examineInfo.status === 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "changeClass",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          _vm.resultShow = true
                                        },
                                      },
                                    },
                                    [_vm._v("变更")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "查验意见" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px" },
                                attrs: {
                                  readonly: "",
                                  type: "textarea",
                                  placeholder: "无查验意见",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.baseInfo.examineV.advice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.baseInfo.examineV,
                                      "advice",
                                      $$v
                                    )
                                  },
                                  expression: "baseInfo.examineV.advice",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "查验项" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    disabled: _vm.isDisabled,
                                    size: "small",
                                  },
                                  on: { change: _vm.checkChange },
                                  model: {
                                    value: _vm.checkboxGroup,
                                    callback: function ($$v) {
                                      _vm.checkboxGroup = $$v
                                    },
                                    expression: "checkboxGroup",
                                  },
                                },
                                _vm._l(
                                  _vm.baseInfo.examineV.itemVList,
                                  function (obj) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: obj.id,
                                        staticStyle: { display: "block" },
                                        attrs: { label: obj.id },
                                        model: {
                                          value: obj.checked,
                                          callback: function ($$v) {
                                            _vm.$set(obj, "checked", $$v)
                                          },
                                          expression: "obj.checked",
                                        },
                                      },
                                      [_vm._v(_vm._s(obj.examineItem))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.id && _vm.isDisabled
            ? _c(
                "col2-block",
                { attrs: { title: "审核情况" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审核结果",
                        prop: "checkStatus",
                        rules: [
                          {
                            required: true,
                            message: "请选择审核结果",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { width: 180, options: _vm.checkStatusOps },
                        model: {
                          value: _vm.form.checkStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkStatus", $$v)
                          },
                          expression: "form.checkStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审核意见",
                        prop: "checkContent",
                        rules: [
                          {
                            required: true,
                            message: "请输入备注内容",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入备注内容，不超过50字",
                          maxlength: 50,
                        },
                        model: {
                          value: _vm.form.checkContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "checkContent", $$v)
                          },
                          expression: "form.checkContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.id
            ? _c(
                "col2-block",
                { attrs: { title: "现场负责人信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "现场负责人" } },
                    [
                      _c(
                        "v-uploader",
                        {
                          ref: "myUpload",
                          attrs: {
                            action: _vm.uploadUrl,
                            fileList: _vm.personHead,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.personHead = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.personHead = $event
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                            _vm._v(
                              "注：图片要求：宽180px高120px，图片小于512KB"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "姓名",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请填写姓名",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系电话",
                        prop: "mobile",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validatePhone,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "证件类型",
                        prop: "cardType",
                        rules: [
                          {
                            required: true,
                            message: "请选择证件类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.cardTypeOps },
                        model: {
                          value: _vm.form.cardType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cardType", $$v)
                          },
                          expression: "form.cardType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "证件号码",
                        prop: "cardNum",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validateIdcard,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.cardNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cardNum", $$v)
                          },
                          expression: "form.cardNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "证件照片",
                        prop: "idCardImg",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validIdCard,
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-uploader",
                        {
                          ref: "myUpload",
                          attrs: {
                            action: _vm.uploadUrl,
                            fileList: _vm.idCardImg,
                            limit: 2,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.idCardImg = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.idCardImg = $event
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                            _vm._v(
                              "注：图片要求：宽180px高120px，图片小于512KB"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { center: "", visible: _vm.resultShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.resultShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("装修查验结果变更")]
          ),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("是否确认将查验结果变更为“通过”？"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.resultShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.changeOK } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }