<template>
  <div class="baseInfoForm-container">
    <form-panel
      ref="form"
      :form="form"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      :submitMethod="submitMethod"
      :submitSuccess="submitSuccess"
    >
      <col2-block title="基础信息">
        <div v-if="id">
          <el-form-item label="所属项目">{{
            baseInfo.communityName
          }}</el-form-item>
          <el-form-item label="房号信息">{{ baseInfo.address }}</el-form-item>
          <el-form-item label="申请业主">{{ baseInfo.userName }}</el-form-item>
          <el-form-item label="联系电话">{{
            baseInfo.userMobile
          }}</el-form-item>
          <el-form-item label="资料状态">{{
            baseInfo.dataStatus === 1 ? "已完善" : "待完善"
          }}</el-form-item>
          <el-form-item label="装修开始时间">{{
            baseInfo.realBeginDate ? baseInfo.realBeginDate : "-"
          }}</el-form-item>
          <el-form-item label="装修结束时间">{{
            baseInfo.realEndDate ? baseInfo.realEndDate : "-"
          }}</el-form-item>
          <el-form-item label="装修阶段">{{
            baseInfo.decorationStageName
          }}</el-form-item>
        </div>
        <el-form-item
          v-if="!id"
          label="业主房产信息"
          prop="roomId"
          :rules="[
            {
              required: true,
              message: '请选择业主房产信息',
              trigger: 'change',
            },
          ]"
        >
          <v-select2
            v-model="form.roomId"
            placeholder="查询房主"
            :width="380"
            v-bind="roomParams"
            @onChange="houseChange"
          ></v-select2>
        </el-form-item>
        <el-form-item
          label="预计开始"
          prop="beginDate"
          :rules="[
            { required: true, message: '请填写预计开始', trigger: 'change' },
          ]"
        >
          <v-datepicker v-model="form.beginDate" :width="180" type="date" />
        </el-form-item>
        <el-form-item
          label="预计结束"
          prop="endDate"
          :rules="[
            { required: true, message: '请填写预计结束', trigger: 'change' },
          ]"
        >
          <v-datepicker v-model="form.endDate" :width="180" type="date" />
        </el-form-item>
      </col2-block>

      <col2-block title="装修管理费">
        <el-form-item
          label="是否缴纳装修管理费"
          prop="isEarnestMoney"
          :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
        >
          <label for="isEarnest-0">
            <input
              type="radio"
              :value="0"
              v-model="form.isEarnestMoney"
              id="isEarnest-0"
            />
            是
          </label>
          <label for="isEarnest-1" style="margin-left: 20px">
            <input
              type="radio"
              :value="1"
              v-model="form.isEarnestMoney"
              id="isEarnest-1"
            />
            否
          </label>
        </el-form-item>
        <el-form-item
          v-if="form.isEarnestMoney === 0"
          label="缴纳金额"
          prop="earnestMoney"
          :rules="[{ required: true, message: '缴纳金额', trigger: 'change' }]"
        >
          <v-input v-model="form.earnestMoney" type="number"></v-input>
        </el-form-item>

        <el-form-item
          label="垃圾清运方式"
          prop="trashClearMode"
          :rules="[
            {
              required: true,
              message: '请选择垃圾清运方式',
              trigger: 'change',
            },
          ]"
        >
          <v-select
            v-model="form.trashClearMode"
            :options="clearModeOps"
          ></v-select>
        </el-form-item>
        <el-form-item
          v-if="form.trashClearMode === 1"
          label="清运费用"
          prop="trashClearFee"
          :rules="[
            { required: true, message: '请填写清运费用', trigger: 'blur' },
          ]"
        >
          <v-input
            v-model="form.trashClearFee"
            type="number"
            @change="valTrashClearFee"
          ></v-input>
        </el-form-item>
      </col2-block>
      <col2-block
        title="现场查验"
        v-if="baseInfo.examineV && baseInfo.examineV.itemVList[0].status === 0 && id"
      >
        <el-form-item label="是否需要" prop="needExamine">
          <v-select
            :disabled="
              baseInfo.examineV.status === 1 ||
              (form.examineInfo && form.examineInfo.status === 1) ||
              baseInfo.checkStatus === 0 ||
              baseInfo.checkStatus === 1
            "
            v-model="form.needExamine"
            :width="180"
            :options="isNeedExamine"
            @change="selectChange"
          />
        </el-form-item>
        <div v-if="form.needExamine === 0">
          <el-form-item
            label="查验人"
            :prop="baseInfo.examineV.examineUserName"
          >
            <v-select2
              :disabled="isDisabled"
              :width="180"
              v-model="baseInfo.examineV.examineUserId"
              v-bind="examineParams"
              :subjoin="roleExtraParams"
              @onChange="examineChange"
            ></v-select2>
          </el-form-item>
          <el-form-item label="计划查验时间">
            <v-datepicker
              :disabled="isDisabled"
              v-model="form.examineInfo.planTime"
              :width="180"
              type="date"
            />
          </el-form-item>
          <el-form-item label="查验结果"
            >{{ this.form.examineInfo.status === 1 ? "通过" : spectionResults }}
            <el-button
              type="text"
              v-if="form.examineInfo.status === 2"
              class="changeClass"
              @click="resultShow = true"
              >变更</el-button
            >
          </el-form-item>
          <el-form-item label="查验意见">
            <el-input
              style="width: 500px"
              readonly
              type="textarea"
              placeholder="无查验意见"
              v-model="baseInfo.examineV.advice"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="查验项">
            <el-checkbox-group
              :true-label="1"
              :false-label="0"
              v-model="checkboxGroup"
              :disabled="isDisabled"
              @change="checkChange"
              size="small"
            >
              <el-checkbox
                style="display: block"
                v-model="obj.checked"
                :label="obj.id"
                v-for="obj in baseInfo.examineV.itemVList"
                :key="obj.id"
                >{{ obj.examineItem }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
      </col2-block>

      <col2-block v-if="id && isDisabled" title="审核情况">
        <el-form-item
          label="审核结果"
          prop="checkStatus"
          :rules="[
            { required: true, message: '请选择审核结果', trigger: 'change' },
          ]"
        >
          <v-select
            v-model="form.checkStatus"
            :width="180"
            :options="checkStatusOps"
          />
        </el-form-item>
        <el-form-item
          label="审核意见"
          prop="checkContent"
          :rules="[
            { required: true, message: '请输入备注内容', trigger: 'change' },
          ]"
        >
          <v-textarea
            v-model="form.checkContent"
            placeholder="请输入备注内容，不超过50字"
            :maxlength="50"
          ></v-textarea>
        </el-form-item>
      </col2-block>

      <col2-block v-if="!id" title="现场负责人信息">
        <el-form-item label="现场负责人">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :fileList.sync="personHead"
          >
            <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
          </v-uploader>
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="name"
          :rules="[{ required: true, message: '请填写姓名', trigger: 'blur' }]"
        >
          <v-input v-model="form.name"></v-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="mobile"
          :rules="[
            { required: true, validator: validatePhone, trigger: 'blur' },
          ]"
        >
          <v-input v-model="form.mobile"></v-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="cardType"
          :rules="[
            {
              required: true,
              message: '请选择证件类型',
              trigger: 'change',
            },
          ]">
          <v-select v-model="form.cardType" :options="cardTypeOps"></v-select>
        </el-form-item>
        <el-form-item
          label="证件号码"
          prop="cardNum"
          :rules="[
            { required: true, validator: validateIdcard, trigger: 'blur' },
          ]"
        >
          <v-input v-model="form.cardNum"></v-input>
        </el-form-item>
        <el-form-item
          label="证件照片"
          prop="idCardImg"
          :rules="[
            { required: true, validator: validIdCard, trigger: 'change' },
          ]"
        >
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :fileList.sync="idCardImg"
            :limit="2"
          >
            <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
          </v-uploader>
        </el-form-item>
      </col2-block>
    </form-panel>
    <!-- 变更按钮弹出窗 -->
    <el-dialog center :visible.sync="resultShow" width="30%">
      <div slot="title" class="dialog-title">装修查验结果变更</div>
      <div style="text-align: center">是否确认将查验结果变更为“通过”？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resultShow = false">取 消</el-button>
        <el-button type="primary" @click="changeOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Col2Block } from '@/components/bussiness'
import { vUploader } from 'components/control'
import { getStoreRoomUserListURL, uploadDecorationBaseURL, uploadUrl, getSettingBase, putExamineUser, getUserListURL,getInitCodeWordList } from '../api'
import { clearModeOps, checkStatusOps, checkNeedExamine } from '../map'
import { mobileRegular, idNumberRegular } from 'common/regular'
import { watch } from 'fs'
import moment from 'moment'
import { generateMapByOpts } from '@/common/utils'
export default {
  components: {
    Col2Block,
    vUploader
  },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      uploadUrl,
      id: this.$route.query.id,
      submitConfig: {
        queryUrl: uploadDecorationBaseURL
      },
      roomParams: {
        searchUrl: getStoreRoomUserListURL,
        request: {
          text: 'ownerName',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'houseId'
        }
      },
      // 查验人列表
      examineParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'username',
          value: 'id'
        },
        response: {
          text: 'username'
        }
      },
      checkStatusOps: checkStatusOps(),
      form: {
        communityId: '',
        userId: '',
        roomId: '',
        beginDate: '',
        endDate: '',
        trashClearMode: 1,
        trashClearFee: 0,
        headerImg: [],
        idCardImg: [],
        cardNum: '',
        mobile: '',
        name: '',
        examineInfo: {
          examineUserId: '', // 查验人id
          examineUserName: '', // 查验人name
          planTime: null,  // 查验时间
          status: null,  // 查验结果
          advice: '', // 查验意见
          examineItemId: '', // 查验项
        },
        needExamine: 0, // 0需要 1不需要

        isEarnestMoney: 0,//("是否需要保证金，0需要，1不需要")
        earnestMoney: '',//("保证金")
        checkStatus: undefined, //("0:审核通过,1:审核不通过,2待审核")
        checkContent: '',//("审核内容")
        cardType: undefined
      },
      personHead: [],
      idCardImg: [],
      baseInfo: {},
      ClearFeeOldValue: 0,
      checkboxGroup: [],
      resultShow: false,
      isNeedExamine: [
        {
          text: '需要',
          value: 0
        },
        {
          text: '不需要',
          value: 1
        }
      ],
      cardTypeOps: [],
      cardTypeMap: {},
    }
  },
  computed: {

    spectionResults () {
      return this.baseInfo.examineV.status === 0 ? '待查验' : this.baseInfo.examineV.status === 1 ? '通过' : '不通过'
    },
    clearModeOps () {
      let [first, ...ops] = clearModeOps // eslint-disable-line
      return ops
    },
    submitMethod () {
      return this.publicParams.id ? 'put' : 'post'
    },
    submitUrl () {
      return this.publicParams.id ? `${uploadDecorationBaseURL}/${this.publicParams.id}` : uploadDecorationBaseURL
    },
    comput () {
      var newTime = new Date().getTime() * 1000000 // 现在
      var planTime = new Date(this.baseInfo.examineV.planTime).getTime() * 1000000  // 计划时间
      var oldTime = new Date().getTime(this.baseInfo.examineV.planTime) * 1000000 - 3 * 60 * 60 * 1000 * 1000000 // 三天前
      return (Number(newTime) > Number(oldTime)) && (Number(newTime) < Number(planTime))
    },
    isDisabled () {
        let examineVStatus = this.baseInfo.examineV && (this.baseInfo.examineV.status === 1 || this.baseInfo.examineV.status === 2)
        return examineVStatus ||
        this.form.needExamine === 1 ||
        this.baseInfo.checkStatus === 1 ||
        this.baseInfo.checkStatus === 0
    },
    roleExtraParams () {
      return {
        communityId: this.form.communityId,
        limitCnt: 50
      }
    },
  },
  created () {
    this.getSelectData()
  },
  mounted () {
    if (this.id) {
      this.form = {
        communityId: '',
        userId: '',
        roomId: '',
        beginDate: '',
        endDate: '',
        trashClearMode: 1,
        trashClearFee: 0,
        headerImg: [],
        idCardImg: [],
        cardNum: '',
        mobile: '',
        name: '',
        examineInfo: {
          examineUserId: '', // 查验人id
          examineUserName: '', // 查验人name
          planTime: null,  // 查验时间
          status: null,  // 查验结果
          advice: '', // 查验意见
          examineItemId: '', // 查验项
        },
        needExamine: 0,

        isEarnestMoney: 0,//("是否需要保证金，0需要，1不需要")
        earnestMoney: '',//("保证金")
        checkStatus: 0, //("0:审核通过,1:审核不通过,2待审核")
        // checkboxGroup: [],
        checkContent: '',//("审核内容")
        cardType: undefined
      }
    } else {
      this.form = {
        beginDate: '',
        endDate: '',
        trashClearMode: 1,
        trashClearFee: 0,
        checkStatus: undefined,
      }
    }
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    // 获取民族
    async getSelectData () {
      let _this = this
      let res = await this.$axios.get(getInitCodeWordList)
      if (res.status === 100) {
        res.data.cardTypeVoList.forEach(item => {
          _this.cardTypeOps.push({
            text: item.value,
            value: item.code
          })
        })
        _this.cardTypeMap = generateMapByOpts(_this.cardTypeOps)
      }
    },
    examineChange (val, echo) {
      console.log(val, 'onChange');
      this.form.examineInfo.examineUserId = val.id
      this.form.examineInfo.examineUserName = val.username
    },
    checkChange (el) {
      this.form.examineInfo.examineItemId = el.toString()
    },
    async changeOK () {

      this.resultShow = false
      this.form.examineInfo.status = 1
      console.log(this.form, 'form');
      const res = await this.$axios.put(putExamineUser, { ...this.form.examineInfo })
      console.log(res, 'resexamine');
      if (res.status == 100) {
        this.$message.success('变更成功！');
      } else {
        this.$message.error('变更失败，请稍后重试！');
      }

    },
    setBaseInfo () {
      let { baseInfo } = this.responseData.data
      this.baseInfo = baseInfo
      if(baseInfo.examineV) {
        this.form.examineInfo.examineUserId = baseInfo.examineV.examineUserId
        baseInfo.examineV.itemVList.forEach((el) => {
          if (el.checked === 1) {
            this.checkboxGroup.push(el.id)
          }
        })
      }    
      // 三天后的时间
      let newTime = Number(new Date().getTime() + 3 * 60 * 60 * 24 * 1000)
      let oldTime = moment(newTime).format('YYYY-MM-DD')
      this.form = {
        communityId: baseInfo.communityId,
        id: baseInfo.id,
        beginDate: baseInfo.beginDate,
        endDate: baseInfo.endDate,
        trashClearMode: Number(baseInfo.trashClearMode),
        trashClearFee: baseInfo.trashClearFee,

        isEarnestMoney: Number(baseInfo.isEarnestMoney),//("是否需要保证金，0需要，1不需要")
        earnestMoney: baseInfo.earnestMoney,//("保证金")
        checkStatus: (Number(baseInfo.checkStatus) === 2) ? undefined : Number(baseInfo.checkStatus), //("0:审核通过,1:审核不通过,2待审核")
        checkContent: (Number(baseInfo.checkStatus) === 2) ? '' : baseInfo.checkContent,//("审核内容")
        examineInfo: {
          advice: baseInfo.examineV?baseInfo.examineV.advice: '',
          planTime: baseInfo.examineV?baseInfo.examineV.planTime ? baseInfo.examineV.planTime : oldTime: null,
          decorationId: baseInfo.id,
          examineItemId: this.checkboxGroup.toString(), // 查验项
          examineUserId: baseInfo.examineV?baseInfo.examineV.examineUserId:'', // 查验人id
          examineUserName: baseInfo.examineV?baseInfo.examineV.examineUserName:'', // 查验人name
          status: baseInfo.examineV?Number(baseInfo.examineV.status): null, // 查验结果
          roomAddress: baseInfo.address,
          roomId: baseInfo.roomId,
          mobile: baseInfo.userMobile,
          contactPeople: baseInfo.userName
        },
        needExamine: Number(baseInfo.needExamine)
      }

      if (baseInfo.examineV && baseInfo.examineV.itemVList.length !==0 && baseInfo.examineV.itemVList[0].status === 1) {
        this.form.examineInfo = {
        }
        this.form.needExamine = 1
      }
      if (this.form.needExamine === 1) {
        console.log('898989898989898');
        this.form.examineInfo = null
      }
      // if (this.form.needExamine === 0) {
      //   this.form.checkStatus = 2
      //   this.form.checkContent = ''
      // }

    },
    selectChange () {
      if (this.form.needExamine === 1) {
        this.form.checkStatus = null
      }
    },
    valTrashClearFee (value) {
      const regTest = /^\d*(\.\d{0,2})?$/
      if (!regTest.test(value)) {
        this.form.trashClearFee = this.ClearFeeOldValue
      }
    },
    validIdCard (rule, value, callback) {
      if (this.idCardImg.length < 2 && this.form.cardType == 1) {
        callback(new Error('请上传身份证正反面照片'))
      } else if (this.idCardImg.length < 1) {
        callback(new Error('请上传证件图片'))
      } else {
        callback()
      }
    },
    houseChange (val) {
      if (val instanceof Object) {
        this.form.communityId = val.communityId
        this.form.userId = val.id
      }
    },
    submitBefore (data) {
      //字符串改成数字的时候'1.'=>1后面的.会被清除
      data.trashClearFee = Number(data.trashClearFee)
      this.personHead.length > 0 && (data.headerImg = this.personHead.map(item => item.url)[0])
      this.idCardImg.length > 0 && (data.idCardImg = this.idCardImg.map(item => item.url))

      if (data.isEarnestMoney == 1) {
        delete data.earnestMoney
      }

      if (data.needExamine === 1 || !this.baseInfo.examineV) {
        data.examineInfo = null
      }
      console.log(data, 'submitBefore-------------------------');
      return true
      // return true
    },
    submitSuccess (data) {
      data.id && (this.publicParams.id = data.id)
      let updateTimeParams = {
        beginDate: this.form.beginDate,
        endDate: this.form.endDate
      }

      this.$emit('contact', {
        index: 1,
        method: 'updateTime',
        data: updateTimeParams
      })
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: '施工人员'
      })
    },
    validatePhone (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入联系电话'))
      } else if (!mobileRegular.test(value)) {
        callback(new Error('请输入正确的联系电话'))
      } else {
        callback()
      }
    },
    validateIdcard (rule, value, callback) {
      //身份证
      let regExp1 = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      //军官证
      let regExp2 = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/
      //护照
      let regExp3 = /^([a-zA-z]|[0-9]){9}$/
      //驾驶证
      let regExp5 = /^\d{12}$/
      //港澳通行证
      let regExp6 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
      //台胞证
      let regExp7 = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
      //户口本
      let regExp8 = /^\d{9}$/
      //警官证
      let regExp9 = /^[0-9]{8,9}$/
      let showError = false
      if (value && value.length > 0) {
        if(this.form.cardType == 1) {
          if (!regExp1.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 2) {
          if (!regExp2.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 3) {
          if (!regExp3.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 5) {
          if (!regExp5.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 6) {
          if (!regExp6.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 7) {
          if (!regExp7.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 8) {
          if (!regExp8.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 9) {
          if (!regExp9.test(value)) {
            showError = true
          }
        }
      }
      if (showError) {
        callback(new Error('证件号码格式不正确'))
      } else {
        callback()
      }
    }
  },
  watch: {
    'form.trashClearFee': {
      handler (newVal, oldVal) {
        const regTest = /^\d*(\.\d{0,2})?$/
        if (!regTest.test(newVal)) {
          this.ClearFeeOldValue = oldVal
        }
      }
    }
  },
}
</script>
<style scoped>
.changeClass {
  color: #029bd7;
  margin-left: 30px;
  font-weight: 600px;
}
.dialog-title {
  text-align: center;
  font-size: 23px;
  font-weight: 700;
  color: #666666;
}
::v-deep .el-textarea__inner {
  height: 96px;
}
</style>
