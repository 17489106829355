<template>
  <div class="buildLog-container">
    <form-panel>
      <div v-if="logInfo.length > 0">
        <div class="logBox" v-for="(item, index) in logInfo" :key="index">
          <div class="top"><span>{{item.stageName}}</span><span>{{item.realEndTime}}</span></div>
          <div class="mid">{{item.remark}}</div>
          <div class="bot" v-for="(item2, index2) in item.orderInfo" :key="index2"><span class="label">工单编号:</span><span class="num" @click="goPageOrder(item2)">{{item2.serialNumber}}{{item2.orderStatus | orderStatus}}</span></div>
        </div>
      </div>
      <div v-else>暂无装修日志</div>
    </form-panel>
  </div>
</template>
<script>
export default {
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      logInfo: []
    }
  },
  filters: {
    orderStatus (val) {
      let text = val === 1 ? '【待处理】' : val === 2 ? '【已处理】' : '【暂无】'
      return text
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setLogInfo()
  },
  methods: {
    setLogInfo () {
      let { logInfo } = this.responseData.data
      this.logInfo = logInfo
    },
    goPageOrder (item) {
      console.log(item)
      this.$router.push({
        name: 'serviceSheetForm',
        query: {
          id: item.orderId,
          orderCode: item.serialNumber
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.logBox {
  padding: 10px 0;
  border-bottom: 1px solid #dedede;
  width: 300px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .mid {
    margin-top: 10px;
  }
  .bot {
    .num {
      margin-left: 10px;
      color: #23527c;
      cursor: pointer;
    }
  }
}
</style>
