var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "applyMaterial-container" },
    [
      _c(
        "form-panel",
        { ref: "form", attrs: { form: _vm.form } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "施工许可",
                prop: "constructionPermit",
                rules: [
                  {
                    required: true,
                    validator: _vm.validIdCard,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.form.constructionPermit,
                    limit: 4,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      return _vm.$set(_vm.form, "constructionPermit", $event)
                    },
                    "update:file-list": function ($event) {
                      return _vm.$set(_vm.form, "constructionPermit", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：图片要求：宽640px高640px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "申请表",
                prop: "applyTable",
                rules: [
                  {
                    required: true,
                    validator: _vm.validIdCard,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.form.applyTable,
                    limit: 6,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      return _vm.$set(_vm.form, "applyTable", $event)
                    },
                    "update:file-list": function ($event) {
                      return _vm.$set(_vm.form, "applyTable", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：图片要求：宽640px高640px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "管理协议书",
                prop: "manageAgreement",
                rules: [
                  {
                    required: true,
                    validator: _vm.validIdCard,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.form.manageAgreement,
                    limit: 6,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      return _vm.$set(_vm.form, "manageAgreement", $event)
                    },
                    "update:file-list": function ($event) {
                      return _vm.$set(_vm.form, "manageAgreement", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：图片要求：宽640px高640px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "承诺书",
                prop: "commitment",
                rules: [
                  {
                    required: true,
                    validator: _vm.validIdCard,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.form.commitment,
                    limit: 4,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      return _vm.$set(_vm.form, "commitment", $event)
                    },
                    "update:file-list": function ($event) {
                      return _vm.$set(_vm.form, "commitment", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：图片要求：宽640px高640px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "图纸",
                prop: "photoPaper",
                rules: [
                  {
                    required: true,
                    validator: _vm.validIdCard,
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.form.photoPaper,
                    limit: 20,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      return _vm.$set(_vm.form, "photoPaper", $event)
                    },
                    "update:file-list": function ($event) {
                      return _vm.$set(_vm.form, "photoPaper", $event)
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：图片要求：宽640px高640px，图片小于512KB"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
            [
              _c("v-button", {
                staticClass: "botBtn",
                attrs: { text: "保存", type: "success" },
                on: { click: _vm.submitApply },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }