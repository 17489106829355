<template>
  <div :id="data"></div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    data: String
  },
  created () {
    this.$nextTick(() => {
      new QRCode(this.data, { // eslint-disable-line
        width: 140,
        height: 140,
        text: this.data
      })
    }, 20)
  },
  methods: {

  }
}
</script>
