<template>
  <div class="BuilderForm-container">
    <form-panel>
      <col2-block title="施工人员信息">
        <el-form class="formBorder" v-for="(item, index) in dataForm" :key="index" ref="form" :model="item" :rules="rules" label-width="168px" label-suffix="：" size="medium">
          <el-row>
            <el-col :span="12">
              <el-form-item :label="`${item.type === 1 ? '现场负责人' : '施工人员'}`">
                <v-uploader
                ref="myUpload"
                :action="uploadUrl"
                :fileList.sync="item.headerImg"
                >
                  <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
                </v-uploader>
              </el-form-item>
              <el-form-item label="姓名" prop="name" :rules="[{ required: true, message: '请填写姓名', trigger: 'blur' }]">
                <v-input v-model="item.name"></v-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="mobile" :rules="[{ required: true, validator: validatePhone, trigger: 'blur' }]">
                <v-input v-model="item.mobile"></v-input>
              </el-form-item>
              <el-form-item label="证件类型" prop="cardType"
                :rules="[
                  {
                    required: true,
                    message: '请选择证件类型',
                    trigger: 'change',
                  },
                ]">
                <v-select v-model="item.cardType" :options="cardTypeOps"></v-select>
              </el-form-item>
              <el-form-item label="证件号码" prop="cardNum" :rules="[{ required: true, validator:(rule, value, callback)=>{ return validateIdcard(rule, value, callback, item.cardType)} , trigger: 'blur' }]">
                <v-input v-model="item.cardNum"></v-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="item.code">
              <el-form-item label="二维码信息">
                <qr-code-temp :data="item.code"></qr-code-temp>
              </el-form-item>
              <el-form-item v-if="item.isEdit !== 1" label="有效期">
                {{item.scheduleEnd | endDate}}
              </el-form-item>
              <el-form-item v-else label="有效期">
                <v-datepicker :maxDate="maxDate" :minDate="minDate" v-model="item.time" type="date"/>
              </el-form-item>
              <el-form-item label="操作" class="operaB">
                <v-button v-if="showCodeHandle(item, 1)" @click="editDate(item)">编辑</v-button>
                <v-button v-if="showCodeHandle(item, 2)" @click="saveDate(item)">保存</v-button>
                <v-button @click="toPrint(item.code)">打印</v-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="证件照片" prop="idCardImg" :rules="[{ required: true, validator:(rule, value, callback)=>{ return validIdCard(rule, value, callback, item.cardType)}, trigger: 'blur' }]">
                <v-uploader
                  ref="myUpload"
                  :action="uploadUrl"
                  :fileList.sync="item.idCardImg"
                  :limit="2"
                  >
                    <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
                  </v-uploader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="operaBtn">
                <v-button v-if="index === dataForm.length -1" text="添加施工人员" @click="addPerson"></v-button>
                <v-button v-if="item.type === 3 && dataForm.length > 1" type="danger" text="删除施工人员" @click="removePerson(index)"></v-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </col2-block>
      <div slot='footerSlot'>
        <v-button class="botBtn" text="保存" type="success" @click="submitBuilder"></v-button>
      </div>
    </form-panel>
  </div>
</template>
<script>
import { Col2Block } from '@/components/bussiness'
import { vUploader } from 'components/control'
import { uploadDecorationStaffURL, uploadUrl, editDateUrl,getInitCodeWordList } from '../api'
import { Row, Col } from 'element-ui'
import qrCodeTemp from './QrCode'
import { mobileRegular, idNumberRegular } from 'common/regular'
import moment from 'moment'
import { generateMapByOpts } from '@/common/utils'
export default {
  components: {
    Col2Block,
    vUploader,
    elRow: Row,
    elCol: Col,
    qrCodeTemp
  },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      uploadUrl,
      submitUrl: uploadDecorationStaffURL,
      form: {},
      dataForm: [{
        headerImg: [],
        idCardImg: [],
        cardNum: '',
        mobile: '',
        name: '',
        type: 3,
        cardType: undefined
      }],
      submitConfig: {},
      rules: {},
      putHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      },
      // 装修预计开始时间
      beginDate: '',
      // 装修预计结束时间
      buildEndDate: '',
      cardTypeOps: [],
      cardTypeMap: {},
    }
  },
  filters: {
    endDate (val) {
      let today = new Date()
      let scheduleEnd = new Date(val)
      let intervalMilliDate = (scheduleEnd - today) / 86400000
      if (intervalMilliDate < -1) {
        return `${val}(-)`
      } else {
        return `${val}(${Math.ceil(intervalMilliDate + 1)})天`
      }
    }
  },

  computed: {
    minDate () {
      return this.beginDate
    },

    maxDate () {
      return this.buildEndDate
    }

    // showCodeHandle () {
    //   return (item, type) => {
    //     console.log('itemitemitem', item, type)

    //     return this.showCodeHandleAction(item, type)
    //   }
    // }

  },
  created () {
    this.getSelectData()
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBuilderInfo()
  },
  methods: {
    // 获取民族
    async getSelectData () {
      let _this = this
      let res = await this.$axios.get(getInitCodeWordList)
      if (res.status === 100) {
        res.data.cardTypeVoList.forEach(item => {
          _this.cardTypeOps.push({
            text: item.value,
            value: item.code
          })
        })
        _this.cardTypeMap = generateMapByOpts(_this.cardTypeOps)
      }
    },
    // 1编辑 2保存
    showCodeHandle (item, type) {
      //  item.codeStatus === 1 && item.isEdit !== 1
      let show = false
      let canHandle = false

      // 不是作废，就可以编辑
      if (item.codeStatus !== 3) {
        if (type === 1) {
          // 1编辑
          if (item.isEdit !== 1) {
            canHandle = true
          }
        } else if (type === 2) {
          // 2保存
          if (item.isEdit === 1) {
            canHandle = true
          }
        }

        // 在这要判断预计结束时间是否在当前时间之后
        if (this.buildEndDate && canHandle) {
          let nowDate = moment().format('YYYY-MM-DD')
          if (moment(nowDate).isBefore(this.buildEndDate)) {
            // 当前时间再预计结束之前
            show = true
          }
        }
      }

      return show
    },

    updateTime (params) {
      if (params) {
        this.beginDate = params.beginDate
        this.buildEndDate = params.endDate
      }
    },

    setBuilderInfo () {
      let { staffInfo, baseInfo } = this.responseData.data

      if (baseInfo) {
        // 取到对应预计的时间
        this.beginDate = baseInfo.beginDate
        this.buildEndDate = baseInfo.endDate
      }
      this.dataForm = staffInfo.map(item => {
        let { headerImg, idCardImg, cardType, ...otherInfo } = item
        return {
          // headerImg: headerImg ? [{ url: headerImg }] : [],
          headerImg: headerImg ? [{ url: headerImg }] : [],
          idCardImg: idCardImg.map(url => {
            return {
              url
            }
          }),
          isEdit: 2,
          time: otherInfo.scheduleEnd,
          cardType: cardType? cardType: undefined,
          ...otherInfo
        }
      })
    },
    validIdCard (rule, value, callback, cardType) {
      if (value.length < 2 && cardType == 1) {
        callback(new Error('请上传身份证正反面照片'))
      } else if (value.length < 1) {
        callback(new Error('请上传证件图片'))
      } else {
        callback()
      }
    },
    validate () {
      let _result = true
      this.$refs.form.forEach(item => {
        item.validate((result, object) => {
          _result = result
        })
      })
      return _result
    },
    submitBuilder () {
      if (!this.publicParams.id) {
        this.$alert('请先添加基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
      let isContinue = this.validate()
      if (!isContinue) {
        return false
      }
      let data = []
      this.dataForm.forEach(item => {
        let setForm = {
          cardNum: item.cardNum,
          mobile: item.mobile,
          name: item.name,
          type: item.type,
          headerImg: item.headerImg[0] ? item.headerImg[0].url : '',
          idCardImg: item.idCardImg.map(img => img.url),
          cardType: item.cardType
        }
        item.id && (setForm.id = item.id)
        data.push(setForm)
      })
      this.$axios.put(`${uploadDecorationStaffURL}${this.publicParams.id}`, data).then(res => {
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          this.$emit('contact', {
            method: 'setActiveLabel',
            data: '施工单位'
          })
        }
      })
    },
    addPerson () {
      let form = {
        headerImg: [],
        idCardImg: [],
        cardNum: '',
        mobile: '',
        name: '',
        type: 3,
        cardType: undefined
      }
      this.dataForm.push(form)
    },
    removePerson (index) {
      this.dataForm.splice(index, 1)
    },
    toPrint (id) {
      let newWindow = window.open()
      let docStr = document.getElementById(id).innerHTML
      newWindow.window.document.body.innerHTML = docStr
      setTimeout(function () {
        newWindow.print()
        newWindow.close()
      }, 100)
    },
    editDate (item) {
      this.$nextTick(() => {
        item.isEdit = 1
      })
    },
    saveDate (item) {
      this.$axios.put(editDateUrl, this.$qs.stringify({
        staffId: item.id,
        passCode: item.code,
        scheduleEnd: item.time
      }), this.putHeader).then(res => {
        if (res.status === 100) {
          this.$message.success('修改成功')
          item.scheduleEnd = item.time
          item.isEdit = 2
        }
      })
    },
    validatePhone (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入联系电话'))
      } else if (!mobileRegular.test(value)) {
        callback(new Error('请输入正确的联系电话'))
      } else {
        callback()
      }
    },
    validateIdcard (rule, value, callback, cardType) {
      //身份证
      let regExp1 = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      //军官证
      let regExp2 = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/
      //护照
      let regExp3 = /^([a-zA-z]|[0-9]){9}$/
      //驾驶证
      let regExp5 = /^\d{12}$/
      //港澳通行证
      let regExp6 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
      //台胞证
      let regExp7 = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
      //户口本
      let regExp8 = /^\d{9}$/
      //警官证
      let regExp9 = /^[0-9]{8,9}$/
      let showError = false
      if (value && value.length > 0) {
        if(cardType == 1) {
          if (!regExp1.test(value)) {
            showError = true
          }
        } else if(cardType == 2) {
          if (!regExp2.test(value)) {
            showError = true
          }
        } else if(cardType == 3) {
          if (!regExp3.test(value)) {
            showError = true
          }
        } else if(cardType == 5) {
          if (!regExp5.test(value)) {
            showError = true
          }
        } else if(cardType == 6) {
          if (!regExp6.test(value)) {
            showError = true
          }
        } else if(cardType == 7) {
          if (!regExp7.test(value)) {
            showError = true
          }
        } else if(cardType == 8) {
          if (!regExp8.test(value)) {
            showError = true
          }
        } else if(cardType == 9) {
          if (!regExp9.test(value)) {
            showError = true
          }
        }
      }
      if (showError) {
        callback(new Error('证件号码格式不正确'))
      } else {
        callback()
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.botBtn {
  width: 168px;
}
.operaB {
  .v-button-container {
    margin-left: 10px;
  }
}
.operaBtn {
  margin-top: 140px;
  .v-button-container {
    margin-left: 10px;
  }
}
.formBorder {
  border-bottom: 1px solid #dedede;
  margin-bottom: 15px;
}
</style>
