var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BuilderForm-container" },
    [
      _c(
        "form-panel",
        [
          _c(
            "col2-block",
            { attrs: { title: "施工人员信息" } },
            _vm._l(_vm.dataForm, function (item, index) {
              return _c(
                "el-form",
                {
                  key: index,
                  ref: "form",
                  refInFor: true,
                  staticClass: "formBorder",
                  attrs: {
                    model: item,
                    rules: _vm.rules,
                    "label-width": "168px",
                    "label-suffix": "：",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: `${
                                  item.type === 1 ? "现场负责人" : "施工人员"
                                }`,
                              },
                            },
                            [
                              _c(
                                "v-uploader",
                                {
                                  ref: "myUpload",
                                  refInFor: true,
                                  attrs: {
                                    action: _vm.uploadUrl,
                                    fileList: item.headerImg,
                                  },
                                  on: {
                                    "update:fileList": function ($event) {
                                      return _vm.$set(item, "headerImg", $event)
                                    },
                                    "update:file-list": function ($event) {
                                      return _vm.$set(item, "headerImg", $event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "tip" }, slot: "tip" },
                                    [
                                      _vm._v(
                                        "注：图片要求：宽180px高120px，图片小于512KB"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "姓名",
                                prop: "name",
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写姓名",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                model: {
                                  value: item.name,
                                  callback: function ($$v) {
                                    _vm.$set(item, "name", $$v)
                                  },
                                  expression: "item.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系电话",
                                prop: "mobile",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validatePhone,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                model: {
                                  value: item.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(item, "mobile", $$v)
                                  },
                                  expression: "item.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件类型",
                                prop: "cardType",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择证件类型",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: { options: _vm.cardTypeOps },
                                model: {
                                  value: item.cardType,
                                  callback: function ($$v) {
                                    _vm.$set(item, "cardType", $$v)
                                  },
                                  expression: "item.cardType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号码",
                                prop: "cardNum",
                                rules: [
                                  {
                                    required: true,
                                    validator: (rule, value, callback) => {
                                      return _vm.validateIdcard(
                                        rule,
                                        value,
                                        callback,
                                        item.cardType
                                      )
                                    },
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                model: {
                                  value: item.cardNum,
                                  callback: function ($$v) {
                                    _vm.$set(item, "cardNum", $$v)
                                  },
                                  expression: "item.cardNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      item.code
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "二维码信息" } },
                                [
                                  _c("qr-code-temp", {
                                    attrs: { data: item.code },
                                  }),
                                ],
                                1
                              ),
                              item.isEdit !== 1
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "有效期" } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("endDate")(item.scheduleEnd)
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "有效期" } },
                                    [
                                      _c("v-datepicker", {
                                        attrs: {
                                          maxDate: _vm.maxDate,
                                          minDate: _vm.minDate,
                                          type: "date",
                                        },
                                        model: {
                                          value: item.time,
                                          callback: function ($$v) {
                                            _vm.$set(item, "time", $$v)
                                          },
                                          expression: "item.time",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "operaB",
                                  attrs: { label: "操作" },
                                },
                                [
                                  _vm.showCodeHandle(item, 1)
                                    ? _c(
                                        "v-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.editDate(item)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm.showCodeHandle(item, 2)
                                    ? _c(
                                        "v-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveDate(item)
                                            },
                                          },
                                        },
                                        [_vm._v("保存")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.toPrint(item.code)
                                        },
                                      },
                                    },
                                    [_vm._v("打印")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件照片",
                                prop: "idCardImg",
                                rules: [
                                  {
                                    required: true,
                                    validator: (rule, value, callback) => {
                                      return _vm.validIdCard(
                                        rule,
                                        value,
                                        callback,
                                        item.cardType
                                      )
                                    },
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-uploader",
                                {
                                  ref: "myUpload",
                                  refInFor: true,
                                  attrs: {
                                    action: _vm.uploadUrl,
                                    fileList: item.idCardImg,
                                    limit: 2,
                                  },
                                  on: {
                                    "update:fileList": function ($event) {
                                      return _vm.$set(item, "idCardImg", $event)
                                    },
                                    "update:file-list": function ($event) {
                                      return _vm.$set(item, "idCardImg", $event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "tip" }, slot: "tip" },
                                    [
                                      _vm._v(
                                        "注：图片要求：宽180px高120px，图片小于512KB"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          { staticClass: "operaBtn" },
                          [
                            index === _vm.dataForm.length - 1
                              ? _c("v-button", {
                                  attrs: { text: "添加施工人员" },
                                  on: { click: _vm.addPerson },
                                })
                              : _vm._e(),
                            item.type === 3 && _vm.dataForm.length > 1
                              ? _c("v-button", {
                                  attrs: {
                                    type: "danger",
                                    text: "删除施工人员",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removePerson(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footerSlot" }, slot: "footerSlot" },
            [
              _c("v-button", {
                staticClass: "botBtn",
                attrs: { text: "保存", type: "success" },
                on: { click: _vm.submitBuilder },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }