<template>
  <div class="buildUnitForm-container">
    <form-panel
      ref="form"
      :form="form"
    >
      <el-form-item label="施工方式" prop="constructionMode" :rules="[{ required: true, message: '请选择施工方式', trigger: 'change' }]">
        <v-select v-model="form.constructionMode" :options="constructionModeOps"></v-select>
      </el-form-item>
      <div v-if="form.constructionMode === 1">
        <el-form-item label="单位名称" prop="constructionCompanyName" :rules="[{ required: true, message: '请填写单位名称', trigger: 'blur' }]">
          <v-input v-model="form.constructionCompanyName"></v-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="licenceImg" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :fileList.sync="form.licenceImg"
            :limit="4"
            >
              <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
            </v-uploader>
        </el-form-item>
        <el-form-item label="资质证书" prop="cerImg" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :fileList.sync="form.cerImg"
            :limit="4"
            >
              <div slot="tip">注：图片要求：宽640px高640px，图片小于512KB</div>
            </v-uploader>
        </el-form-item>
      </div>
      <el-form-item label="负责人" prop="headerImg" :rules="[{ required: true, message: '请上传负责人头像', trigger: 'change' }]">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :imgUrls.sync="form.headerImg"
            >
              <div slot="tip">注：建议宽180px高120px，图片小于512KB</div>
            </v-uploader>
        </el-form-item>
      <el-form-item label="姓名" prop="name" :rules="[{ required: true, message: '请填写姓名', trigger: 'blur' }]">
        <v-input v-model="form.name"></v-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="mobile" :rules="[{ required: true, message: '请填写联系电话', trigger: 'blur' },{ validator: validatePhone, trigger: 'blur' }]">
        <v-input v-model="form.mobile"></v-input>
      </el-form-item>
      <el-form-item label="证件类型" prop="cardType"
        :rules="[
          {
            required: true,
            message: '请选择证件类型',
            trigger: 'change',
          },
        ]">
        <v-select v-model="form.cardType" :options="cardTypeOps"></v-select>
      </el-form-item>
      <el-form-item label="证件号码" prop="cardNum" :rules="[{ required: true, validator: validateCardNum, trigger: 'blur' }]">
        <v-input v-model="form.cardNum"></v-input>
      </el-form-item>
      <el-form-item label="证件照片" prop="idCardImg" :rules="[{ required: true, validator: validIdCard, trigger: 'blur' }]">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="form.idCardImg"
          :limit="2"
          >
            <div slot="tip">注：图片要求：宽180px高120px，图片小于512KB</div>
          </v-uploader>
      </el-form-item>
      <div slot='footerSlot'>
        <v-button class="botBtn" text="保存" type="success" @click="submitBuildUnit"></v-button>
      </div>
    </form-panel>
  </div>
</template>
<script>
import { uploadDecorationConstructionURL, uploadUrl,getInitCodeWordList } from '../api'
import { constructionModeOps } from '../map'
import { vUploader } from 'components/control'
import { mobileRegular } from 'common/regular'
import { generateMapByOpts } from '@/common/utils'
export default {
  components: {
    vUploader
  },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      uploadUrl,
      submitMethod: 'put',
      constructionModeOps,
      form: {
        cerImg: [],
        constructionCompanyName: '',
        constructionMode: 1,
        headerImg: '',
        idCardImg: [],
        cardNum: '',
        licenceImg: [],
        mobile: '',
        name: '',
        staffId: '',
        cardType: undefined
      },
      cardTypeOps: [],
      cardTypeMap: {},
    }
  },
  created () {
    this.getSelectData()
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBuildUnitInfo()
  },
  methods: {
    // 获取民族
    async getSelectData () {
      let _this = this
      let res = await this.$axios.get(getInitCodeWordList)
      if (res.status === 100) {
        res.data.cardTypeVoList.forEach(item => {
          _this.cardTypeOps.push({
            text: item.value,
            value: item.code
          })
        })
        _this.cardTypeMap = generateMapByOpts(_this.cardTypeOps)
      }
    },
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (!regExp.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    },
    setBuildUnitInfo () {
      let { businessLicense, certificate, idCardImg, headerImg, constructionType, cardType, ...otherData } = this.responseData.data.companyInfo
      this.form = {
        ...otherData,
        licenceImg: businessLicense.map(url => { return { url } }),
        cerImg: certificate.map(url => { return { url } }),
        idCardImg: idCardImg.map(url => { return { url } }),
        headerImg,
        cardType: cardType? cardType: undefined,
        constructionMode: constructionType
      }
    },
    submitBuildUnit () {
      if (!this.publicParams.id) {
        this.$alert('请先添加基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
      let isContinue = this.$refs.form.validate()
      if (!isContinue) {
        return
      }
      let data = this.form
      let params = {
        id: this.publicParams.id,
        constructionMode: data.constructionMode,
        headerImg: data.headerImg,
        idCardImg: data.idCardImg.map(img => img.url),
        cardNum: data.cardNum,
        mobile: data.mobile,
        name: data.name,
        staffId: data.staffId,
        cardType: data.cardType
      }
      if (this.form.constructionMode === 1) {
        params.constructionCompanyName = data.constructionCompanyName
        params.cerImg = data.cerImg.map(img => img.url)
        params.licenceImg = data.licenceImg.map(img => img.url)
      }
      this.$axios.put(uploadDecorationConstructionURL, params).then(res => {
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          this.$emit('contact', {
            method: 'setActiveLabel',
            data: '申请资料'
          })
        }
      })
    },
    validIdCard (rule, value, callback) {
      if (value.length < 1) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    },
    validateCardNum (rule, value, callback) {
      //身份证
      let regExp1 = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      //军官证
      let regExp2 = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/
      //护照
      let regExp3 = /^([a-zA-z]|[0-9]){9}$/
      //驾驶证
      let regExp5 = /^\d{12}$/
      //港澳通行证
      let regExp6 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
      //台胞证
      let regExp7 = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
      //户口本
      let regExp8 = /^\d{9}$/
      //警官证
      let regExp9 = /^[0-9]{8,9}$/
      let showError = false
      if (value && value.length > 0) {
        if(this.form.cardType == 1) {
          if (!regExp1.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 2) {
          if (!regExp2.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 3) {
          if (!regExp3.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 5) {
          if (!regExp5.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 6) {
          if (!regExp6.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 7) {
          if (!regExp7.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 8) {
          if (!regExp8.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 9) {
          if (!regExp9.test(value)) {
            showError = true
          }
        }
      }
      if (showError) {
        callback(new Error('证件号码格式不正确'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.botBtn {
  width: 168px;
}
</style>
